////////////////////////////////////////////////////////////////
//
//
//   These are the default variables
//   from which everything else is calculated.
//
////////////////////////////////////////////////////////////////


// Colors
$black:        hsl(0, 0%, 4%) ;
$black-bis:    hsl(0, 0%, 7%) ;
$black-ter:    hsl(0, 0%, 14%) ;
$grey-darker:  hsl(0, 0%, 21%) ;
$grey-dark:    hsl(0, 0%, 29%) ;
$grey:         hsl(0, 0%, 48%) ;
$grey-light:   hsl(0, 0%, 71%) ;
$grey-lighter: hsl(0, 0%, 86%) ;
$grey-lightest: hsl(0, 0%, 93%) ;

$white-ter:    hsl(0, 0%, 96%) ;
$white-bis:    hsl(0, 0%, 98%) ;
$white:        hsl(0, 0%, 100%) ;

$orange:       hsl(14,  100%, 53%) ;
$yellow:       hsl(48,  100%, 67%) ;
$green:        hsl(141, 53%,  53%) ;
$turquoise:    hsl(171, 100%, 41%) ;
$cyan:         hsl(204, 71%,  53%) ;
$blue:         hsl(217, 71%,  53%) ;
$purple:       hsl(271, 100%, 71%) ;
$red:          hsl(348, 86%, 61%) ;

////////////////////////////////////////////////////////////////
//
//  Custom color
//
////////////////////////////////////////////////////////////////


$logic-blue:    #005cb9;
$logic-red:     #d50037;
$logic-yellow:  #ffb600;
$logic-green:   #00c08b;
$whiter:       #f2f1f0;


// Typography
$family-sans-serif: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif ;
$family-monospace: monospace !default;
$render-mode: optimizeLegibility !default;

$size-1: 3rem !default;
$size-2: 2.5rem !default;
$size-3: 2rem !default;
$size-4: 1.5rem !default;
$size-5: 1.25rem !default;
$size-6: 1rem !default;
$size-7: 0.75rem !default;

$weight-light: 300 !default;
$weight-normal: 400 !default;
$weight-medium: 500 !default;
$weight-semibold: 600 !default;
$weight-bold: 700 !default;

// Spacing
$block-spacing: 1.5rem !default;

// Responsiveness
// The container horizontal gap, which acts as the offset for breakpoints
$gap: 32px !default;
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px !default;
// 960px container + 4rem
$desktop: 960px + (2 * $gap) !default;
// 1152px container + 4rem
$widescreen: 1152px + (2 * $gap) !default;
$widescreen-enabled: true !default;
// 1344px container + 4rem
$fullhd: 1344px + (2 * $gap) !default;
$fullhd-enabled: true !default;

// Miscellaneous
$easing: ease-out !default;
$radius-small: 2px !default;
$radius: 4px !default;
$radius-large: 6px !default;
$radius-rounded: 290486px !default;
$speed: 86ms !default;

// Flags
$variable-columns: true !default;
$rtl: false !default;

// Effects
$smooth: all .2s ease-in-out !default;
$shadow-main: 0 0 10px rgba(0,0,0,0.15);



////////////////////////////////////////////////////////////////
//
//  Here we're importing bulma.
//
////////////////////////////////////////////////////////////////


@import "~bulma/bulma.sass";


////////////////////////////////////////////////////////////////
//
//  Import swiper
//
////////////////////////////////////////////////////////////////

// Import Swiper React components

@import "~swiper/swiper.scss";
@import "~swiper/components/navigation/navigation.scss";
@import "~swiper/components/pagination/pagination.scss";

////////////////////////////////////////////////////////////////
//
//  Import fonts.
//
////////////////////////////////////////////////////////////////


@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap');

$family-title: 'Ubuntu', sans-serif;
$family-body: 'Open Sans', sans-serif;




////////////////////////////////////////////////////////////////
//
//  We can add any CSS after this that we want.
//
////////////////////////////////////////////////////////////////

html {
  scroll-behavior: smooth;
  width: 100vw;
  overflow-x: hidden;
}

h1,h2,h3,h4,h5,h6 {
  font-family: $family-title;
}

body {
  font-family: $family-body;
}

.app {
  text-align: center;
}

.app-logo {
  height: 20vmin;
  pointer-events: none;
  margin-bottom: 50px;
}

.app-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.navbar-header {
  margin: 0 15px;
}

.navbar-menu .navbar-item {
  padding: 1.5rem 1.5rem;
  transition: $smooth;
  -webkit-transition: $smooth;

  .is-active {
    color: $logic-blue;
  }

  &:hover{
    background-color: transparent!important;
    color: $logic-blue;
    margin-bottom: -5px;
  }
  
}

.navbar-item:last-child{
  font-weight: 600;

  &:hover{
    margin-bottom: 0;
  }

}


.hero-body {
  background-image: url(../images/animated_blocks.svg);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 100%;
  background-size: contain;

  .is-hero-title{
    display: flex;
  }

  .Typewriter{

    .Typewriter__cursor{
      font-weight: 200;
    }

    span{
      color: $logic-blue;
    }
  }
}

.button{
  font-weight: 600;

  &.is-primary {
    color: $white;
    background-color: $logic-blue;
    transition: $smooth;
    -webkit-transition: $smooth;

    &:hover {
      background-color: $blue;
    }

  }

  &.is-negative {
    color: $logic-blue;

    &:hover{
      background-color: $grey-lightest;
    }
  }

}

a, button {
  transition: $smooth;
  -webkit-transition: $smooth;
}

a {
  color: $logic-blue;

  &:hover{
    color: $blue;
  }

  &.not-button{
    font-weight: 600;

    span{
      opacity: 0;
      transition: $smooth;
    }

    &:hover{
      margin-left: 5px;

      span{
        opacity: 1;
      }
    }
  }

}

.float {
  &--left {
    float: left;
  }
  &--right {
    float: right;
  }
}

.bg {
  &--light {
    background-color: $whiter;
  }
  &--primary {
    background-color: $logic-blue;
  }
}

.content {
  &.is-light {

    h1,h2,h3,h4,h5,h6,p{
      color:white;
    }

    a{
      font-weight: 600;
      transition: $smooth;
    }
    
  }
}

.contour-icon{
  width: 125px;
  height: 125px;
  padding: 35px;
  background-image: url(../images/lb-contour.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  filter: drop-shadow(0px 0px 10px $grey-lighter);
}


.no-shadow{
    box-shadow: none;
}

#scrollArrow {
  position: absolute;
  bottom: 2rem;
  width: 100%;
  text-align:center;
  color: $logic-blue;
  font-size: 32px;
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce{
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

// Section specific styling


#about {

  .contour-icon{
    width: 100px;
    height: 100px;
  }

  .svg-inline--fa {
    font-size: 32px;
  }

  .box:nth-child(1) {
    .svg-inline--fa {
      color: $logic-blue;
    }
  }

  .box:nth-child(2) {
    .svg-inline--fa {
      color: $logic-yellow;
    }
  }

  .box:nth-child(3) {
    .svg-inline--fa {
      color: $logic-red;
    }
  }
}

#features {
  font-size: inherit;
}

#gallery {

  
  .modal{
    transition: all 1s ease-in-out;
  }

  .gallery-nav {
    font-size: 32px;
    color: $logic-blue;
    transition: $smooth;
    -webkit-transition: $smooth;

    &:hover {
      color: $blue;
    }
  }

  .gallery-prev:hover {
    margin-left: -10px;
  }
  .gallery-next:hover {
    margin-right: -10px;
  }
}

.swiper-slide{
  font-size: 20px;
}

.portfolio {

  &--item{
    figure{
      box-shadow: rgb(255, 255, 255) 17px 14px 25px 0px inset, rgba(0, 0, 0, 0.15) 3px 7px 15px 2px;
      transition: $smooth;
      transform: scale(0.75);
      border-radius: 10px 10px 10px 10px;
      cursor:pointer;

      img{
        border-radius: 10px 10px 10px 10px;
      }
    }

  }

  &--item:hover{

    figure{
      transform: scale(.85);
      box-shadow: rgba(255, 255, 255, 0.5) 10px 10px 18px 0px inset, rgba(0, 0, 0, 0.15) 0px 28px 30px 8px;
    }

    > .portfolio--button{
      opacity: 1;
    }

  }

  &--button{
    width: 100%;
    height: 100%;
    color: $logic-blue;
    font-size: 24px;
    position: relative;
    display: flex;
    float: right;
    bottom: 50px;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: $smooth;
  }

  &--modal{
    div{
      margin-bottom: 2rem;
    }
    
    &--title{
      text-align: center;

      img{
        height: 80px;
      }
    }

    &--tags{
      display: flex;
      justify-content: center; 
      align-items: center;
      font-weight: 600;
      
      .tag:not(body){
        background: $white;
        color: $logic-blue;
        box-shadow: $shadow-main;
        font-size: 14px;
      }
    }
    
    &--description{
      display: flex;
      align-items: center;
      
      img{
        margin-right: 1rem;
        width: 240px;
      }
    } 
    
    img{
      margin-right: 1rem;
      width: 240px;
    }

    &--buttons{
      text-align: center;
      margin-bottom: 0!important;
    }
  }
}


#contact {
  background: linear-gradient(180deg, $white 30%, $whiter 30% 80%, $white 80%);

  .box{
    padding: 3rem;
    box-shadow: $shadow-main;
  }
  
  .field-group{
    width: 100%;
    padding: 1rem 0;
    position: relative;
    display: flex;
    transition: $smooth;
  }

  .field{
    margin: 1rem 0;
  }

  .input-label {
    position: absolute;
    align-items: center;
    color: $grey-light;
    font-weight: 400;
    font-size: 1.25rem;
    cursor: text;
    transition: $smooth;
  }

  .input-field, textarea {
    width: 100%;
    border: none;
    outline: none;
    border-radius: 0;
    color: $logic-blue;
    background: transparent;
    font-size: 1.5rem;
    font-weight: 600;
    transition: $smooth;

    &:not(:placeholder-shown), &:focus {

      ~.input-label {
        font-size: 1rem;
        margin-top: -1.5rem;
      }
      ~.input-bar:before {
        transform: scaleX(1);
        transform-origin: left center;
      }
    }

    &:invalid {
      outline:none!important;
      border:none!important;
      box-shadow:none;
      color: $logic-red;

      ~.input-label {
        color: $logic-red;
      }
      ~.input-bar:before {
        background: $logic-red;
      }

    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px transparent inset;
      -webkit-text-fill-color: white !important;
    }
  
  }

  .input-bar {
    background: $grey-lightest;
    content: '';
    width: 100%;
    height: 3px;
    position: absolute;
    bottom: 0;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 3px;
      background: $logic-blue;
      transform: scaleX(0);
      transition: $smooth;
    }
  }

}

#footer-top {
  padding: 3rem 1.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  
  .container{
    background-image: url(../images/address-map.svg);
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: center;
    background-size: contain;
  }

  .column{
    &::before{
      content: " ";
      width: 5%;
      height: 5px;
      border-radius: 5px;
      position: absolute;
      top: -15px;
    }
  }
  .column:nth-of-type(2)::before{
    background-color: $logic-red;
  }
  .column:nth-of-type(3)::before{
    background-color: $logic-yellow;
  }
  .column:nth-of-type(4)::before{
    background-color: $logic-blue;
    right: 0;
  }

  ul{
    margin-left: 0;

    li{
      list-style: none;
      margin: .75em 0;
    }

    a{
      color: $grey;

      &:hover{
        color: $logic-blue;
        margin-left: 5px;
      }
    }

  }

  .footer-details{
    div{
      margin-bottom: 32px;
    }

    img{
      width: 180px;
    }

    .svg-inline--fa{
      font-size: 42px;
      margin-left: 25px;
      color: $grey-light;
      transition: $smooth;

      &:hover{
        color: $logic-blue;
        margin-bottom: -5px;
      }
    }
  }
}

#footer-bottom {
  padding: 1rem 1.5rem;

  p{
    color: $logic-blue;
  }
}

@media (max-width: $desktop) {
  .hero-body{
    background-size: 50%;

    .is-hero-title{
      display: inline;
    }
  }

  #contact{
    .form{
      /* Fix field font size */
      .input-field, .input-label{
        font-size: 1rem;
      }

      /* Align form button for better display */
      .control{
        text-align: center;
      }
    }
  }

  #footer-top{
    .container{
      background-size: 30%;
    }
  }

  .show-on-hd{
    display: none;
  }
}
/* Mobile Styling */

@media (max-width: $tablet) {

  /* Mobile Menu customization */
  .navbar-menu {
    transition: $smooth;
    width: 50%;
    float: right;
    padding-top: 50px;
    margin-top: -50px;

    .navbar-item{
      padding: .5rem 1.5rem;
    }
  }

  .navbar-burger{
    color: $logic-blue;
    transition: $smooth;
    
    span{
      height: 3px;
      width: 20px;

      &:nth-child(1){
        top: calc(50% - 8px);
      }
      &:nth-child(2){
        top: calc(50% + -1px);
      }
      &:nth-child(3){
        top: calc(50% + 6px);
      }
    }

    &.is-active {
      span {
        transform-origin: 0 1px;

        &:nth-child(1){
          transform: rotate(45deg)!important;
        }
        &:nth-child(3){
          transform: rotate(-45deg)!important;
        }
      }
    }
  }
  
  /* Disable Hero Image & Format TypeWriter */
  .hero-body{
    background-image: none;

    .is-hero-title{
      display: inline;
    }
  }

  #about {
    /* Resize About checkmarks */
    .contour-icon{
      width: 50px;
      height: 50px;

      svg{
        font-size: 24px;
      }
    }

    /* Add spacing between 'laptop_guy.svg' and next section */
    img.float--right{
      margin-bottom: 50px;
    }
  }

  #technology{
    /* Fix Technology logos sizes and spacing */
    .contour-icon{
      padding: 15px;
      height: 75px;
    }

    /* Add spacing between 'chem_lab.svg' and next section */
    img.float--right{
      margin-bottom: 50px;
    }
  }

  #contact{
    /* Remove rocket image & fix removal caused issues*/
    img{
      display:none;
    }

    .column.is-half{
      display:flex;
    }
    
    background: linear-gradient(180deg, white 50%, #f2f1f0 30% 80%, white 80%);

    .form{
      /* Fix field font size */
      .input-field, .input-label{
        font-size: 1rem;
      }

      /* Align form button for better display */
      .control{
        text-align: center;
      }
    }
  }

  #footer-top{
    /* Remove excessive space between footer-top and footer-bottom */
    padding: 0rem 1.5rem;
    margin-bottom: 0;

    /* Remove footer map */
    .container{
      background-image: none;
    }
  
    /* Remove footer column decorative lines */
    .column::before{
      content: none!important;
    }

    /* Align footer branding to the left */
    .footer-details{
      text-align: left!important;
    }
    
    .footer-social{
      svg{
        margin-left: 0!important;
        margin-right: 25px;
      }
    }
  }

}